@import '~@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine-dark/sass/_ag-theme-alpine-dark-mixin';

.ag-theme-alpine-dark {
  @include ag-theme-alpine-dark(
    (
      header-height: 25px,
      grid-size: 4px,
      font-size: 12px,
    )
  );

  .ag-cell,
  .ag-header-cell {
    padding-left: 4px;
    padding-right: 4px;
    letter-spacing: normal;
  }

  .ag-react-container {
    height: 100%;
  }

  .ag-details-row {
    height: 100%;
  }
}
