/* @import-normalize; */

html,
body,
.root {
  height: 100%;
  margin: 0px;
  /* background-color: #f4f6f8; */
}

/* Investigate: should this be a global overiding style using !important? */
.edited {
  background-color: blue !important;
}
