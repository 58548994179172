/*** Overrides for react-virtualized styles ***/
.ReactVirtualized__Table__Grid {
  background-color: white;
  /* border-bottom: solid 1px red;
  border-right: solid 1px red; */
}
.ReactVirtualized__Table__headerRow {
  background-color: #f6f7fa;
  border: solid 1px #b0bcd1;
  padding-right: 0 !important;
}
.ReactVirtualized__Table__row {
  border-bottom: solid 1px #dde2eb;
  border-left: solid 1px#ddE2EB;
  border-right: solid 1px #dde2eb;
}
